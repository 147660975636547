<template>
  <v-row justify="center" class="mx-0 my-4">
    <v-col cols="12" md="8" class="text-center">
      <h1>
        Pagina niet gevonden
      </h1>
      <div class="my-12 text-h6">De pagina die u probeert te bereiken is niet gevonden</div>
      <v-btn color="primary" @click="home">Terug naar de startpagina</v-btn>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'page-not-found',
  data() {
    return {};
  },
  created() {
    this.$hero.$emit('setTitle', '');
    this.$hero.$emit('setBreadcrumbs', false);
  },
  methods: {
    home() {
      this.$router.push({ name: 'home' });
    },
  },
};
</script>

<style scoped lang="scss">

</style>
